#NavDark {
    background: #3f3f3f;
    box-shadow: 0px 1px 15px rgb(237, 237, 237);
    border-bottom: 1px solid rgb(1, 1, 1);
    color: white;
}
.Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1% 0 2%;
    box-shadow: 0px 1px 15px rgb(165, 165, 165);
    border-bottom: 1px solid rgb(212, 212, 212);
    background: white;
}
.Nav .User, 
.Nav .logo {
    margin: 0;
    padding: 7px 0 7px 0;
}
.Nav .User {
    margin-right: 4.5px;
}

.Nav .User {
    display: flex;
    align-items: center;
}

.Nav .User .Name {
    margin-right: 7px;
}
@media screen and (max-width: 576px) {
    .Nav .User .Name {
        display: none;
    }
    .Nav {
        border-bottom: none;
    }
    .Nav .logo {
        margin-left: 5px
    }
}

.Nav .User .Icon {
    font-size: 20px;
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;
}
.logo {
    animation: floatLeft 0.35s ease-out
}
.User {
    animation: floatRight 0.35s ease-out
}
@keyframes floatLeft {
    from {
        opacity: 0;
        transform: translateX(-40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes floatRight {
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}