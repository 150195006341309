/* body {
  background: black;
} */
.Sider {
  overflow: hidden;
  padding: 0;
  margin: 25px 0 0 0;
  background: white;
  animation: showLeftToRight 0.35s ease-out;
}
.ant-col {
  background: white;
}
.LecturerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 93vh;
  background: #fce7cc;
  border-left: 1px solid rgb(212, 212, 212);
}
#LecturerContentDark {
  background: black;
}
.LecturerShow {
  background: white;
  height: 90vh;
  width: 99%;
  border-radius: 20px;
  padding: 10px;
  margin: 8px;
  justify-content: center;
  overflow: scroll;
  animation: fade 0.2s linear;
}
.logOutBtn {
  text-align: left;
  width: 98%;
  margin: 0 0 8px 11px;
}
.createSlotBtn {
  margin-left: 3%;
}
.DisplayBtn {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}
.LecturerShowBtn {
  display: none;
  z-index: 1;
}

.lecturerCreatedSlots {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  padding: 0 0 20px 0;
}
.lecturerCreatedSlots ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: calc(100% / 7);
  min-width: 120px;
  text-align: center;
}
.lecturerCreatedSlots ul li:first-child {
  font-size: 16px;
  font-weight: 600;
  margin: 4px 0 4px 0;
}
.lecturerCreatedSlots ul li:nth-child(2) {
  font-size: 14px;
}
.LecturerSelectedDates {
  color: #f18057;
  font-weight: 500;
}
.ant-empty {
  position: sticky;
  left: 50%;
  margin-top: 50px;
}
.addLocationBtn {
  margin-top: 15px;
  margin-left: 3.5px;
}
.tableOfLocations {
  margin-top: 10px;
  width: 100%;
  animation: fade 0.25s linear;
}
.locationDeleteBtn {
  color: red;
  margin-left: 20px;
}
.editLocationForm {
  animation: fade 0.2s linear;
}
.CalenderViewContainer  {
  animation: fade 0.25s linear;
}
.editLocationForm Form {
  width: 50%;
}
.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  left: 5px;
  margin: 15px 0 0 3.4%;
  padding: 0 5px 12px 5px;
  border-bottom: 1px solid rgb(227, 226, 226);
  width: 93%;
}
.sectionTitle Button {
  margin: 0;
}
.createdSlotTabs {
  width: 100%;
}
.createdSlotTabs .ant-tabs-nav {
  width: 93.5%;
  position: sticky;
  left: 3.5%;
}
.CalenderViewSupportBtn {
  margin-left: 3.5%;
}
.CalenderViewSupportBtn Button {
  margin: 5px 5px 7px 0;
}
.CalenderViewSupportBtn Button:last-child {
  margin-left: 8px;
}
.tableviewcontainer {
  width: 100%;
  overflow: scroll;
  animation: fade 0.3s linear;
}
.slotDisplay {
  position: relative;
  margin: 12.9px 3px 22px 0;
  padding: 10px 0 10px 0;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  background: #fffcfb;
  box-shadow: 0px 0px 4px #fc5d5d inset;
}
.slotDisplayAvaiable {
  box-shadow: 0px 0px 4px green inset;
}
/* .slotDisplay::before {
  content: '';
  position: absolute;
  top: 6px;
  left: 7px;
  width: 7px;
  height: 7px;
  background: #ff8282;
  border-radius: 50%;
} */
.slotDisplay:hover {
  color: white;
  background: #fd7d1a;
  box-shadow: 0px 0px 15px grey inset;
}
.hoverSlotInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lecturerStatisticNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 7px 0;
  animation: showUp 0.45s ease-out;
}
.cardStatisticNumber {
  box-shadow: 1px 1px 5px rgb(175, 175, 175);
  transition: all 0.14s linear;
}
.cardStatisticNumber:hover {
  box-shadow: 2px 2px 12px rgb(152, 152, 152);
}
.Chart {
  position: relative;
  height: 60vh;
  width: 100%;
  text-align: center;
  margin: 0 0 0 0;
}
.displayGraph {
  position: absolute;

  left: 50%;
  transform: translate(-50%, 0);
}
.heightOfOr {
  height: 50vh;
}

@media only screen and (max-width: 576px) {
  .heightOfOr {
    height: 30px;
  }
  .btnContainer {
    max-width: 120px;
  }
  .LecturerShow {
    height: 100vh;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .LecturerShowBtn {
    display: block;
    margin-left: 10px;
  }
  .menuAnimation ul{
    animation: menu 0.3s ease-out;
  }
  /* .logOutBtn {
    animation: logout 0.3s ease-out;
  } */
  .Sider {
    margin: 0;
    border-bottom: 1px solid rgb(212, 212, 212);
    padding-bottom: 12px;
  }
  .LecturerContent {
    border-left: none;
  }
  .createSlotBtn {
    margin-left: 1.7%;
  }
  .locationDeleteBtn {
    margin-left: 0;
  }
  .editLocationForm Form {
    width: 90%;
  }
}
.animateBox {
  animation: anibox 0.15s linear;
}
@keyframes anibox {
  from {
    opacity: 0;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes menu {
  0% {
    transform: translateY(-80%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes logout {
  0% {
    transform: translateY(-1000%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes showLeftToRight {
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes showUp {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes showLeftToRight2 {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}